export const photos = [
    {
        src: "assets/IMG_2815.JPEG",
        width: 4,
        height: 3
    },
    {
        src: "assets/IMG_20191027_184402~2.jpg",
        width: 16,
        height: 9
    },
    {
        src: "assets/IMG_2818.JPEG",
        width: 4,
        height: 3
    },
    {
        src: "assets/IMG_20191220_145848.jpg",
        width: 4,
        height: 3
    },
    {
        src: "assets/IMG_3487.JPEG",
        width: 4,
        height: 3
    },
    {
        src: "assets/IMG_3496.JPEG",
        width: 4,
        height: 3
    },
    {
        src: "assets/IMG_3498.JPEG",
        width: 4,
        height: 3
    },
    {
        src: "assets/IMG_3501.JPEG",
        width: 3,
        height: 4
    },
    {
        src: "assets/IMG_3502.JPEG",
        width: 4,
        height: 3
    },
    {
        src: "assets/IMG_20190701_171522.jpg",
        width: 3,
        height: 4
    },
    {
        src: "assets/received_891415107867987.jpeg",
        width: 4,
        height: 3
    },
];
